
export const sliderData = [
    {      
      heading: "Shoes Villa",
      desc: "Up to 30% off on all onsale proucts.",
    },
    {     
      heading: "Women Fashion",
      desc: "Up to 30% off on all onsale proucts.",
    },
    {      
      heading: "Men Fashion",
      desc: "Up to 30% off on all onsale proucts.",
    },
    {     
      heading: "Awesome Gadgets",
      desc: "Up to 30% off on all onsale proucts.",
    },
  ];