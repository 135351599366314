import React from "react";
import { HashLink } from "react-router-hash-link";
import { Helmet, Scrolltop } from "../../components";

const Terms = () => {
  return (
    <Helmet title="terms and conditions">
      <section>
        <div className="page" id="page_1">
          <h2 className=" text-center mb-5" style={{ color: "#F49934" }}>
            Returns and Refunds Policy{" "}
          </h2>

          <h3 style={{ color: "#F49934" }}>Contents</h3>

          <p>
            <HashLink to="/terms#q1">
              <span className="h2 ">1. </span>
              <span className="h3">Introduction</span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q2">
              <span className="h2">2. </span>
              <span className="h3">
                Period and conditions for accepting return requests{" "}
              </span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q3">
              <span className="h2">3. </span>
              <span className="h3">Items that cannot be returned </span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q4">
              <span className="h2">4. </span>
              <span className="h3">Returns Packaging</span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q5">
              <span className="h2">5. </span>
              <span className="h3">Refunds</span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q5">
              <span className="h2">6. </span>
              <span className="h3">
                Rejected Return and Refund Requests and Confiscation{" "}
              </span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q5">
              <span className="h2">7. </span>
              <span className="h3">No exchanges</span>
            </HashLink>
          </p>
          <p>
            <HashLink to="/terms#q6">
              <span className="h2">8. </span>
              <span className="h3">Additional information</span>
            </HashLink>
          </p>
        </div>
        <div className="page mt-5" id="page_2">
          <p id="q1" style={{ color: "#F49934" }}>
            <span className="h2 me-4">1. </span>
            <span className="h3">Introduction</span>
          </p>

          <p>
            At eShop, our priority is to offer you an experience pleasant every
            time you order on our site. However, you may occasionally need to
            return certain items you have ordered.
          </p>

          <p>
            This Returns and Refunds Policy sets out our terms for accepting
            return requests and issuing refunds on behalf of our sellers. It
            also explains when we will not be able to accept return requests, or
            issue refunds.
          </p>
        </div>

        <div className="page mt-5" id="page_3">
          <p id="q2" style={{ color: "#F49934" }}>
            <span className="h2 me-4">2. </span>
            <span className="h3">
              Period and conditions for accepting return requests
            </span>
          </p>

          <p>
            In accordance with the rules set out in this Returns and Refunds,
            sellers operating on the eShop platform offer the possibility of
            returning the majority of the articles ordered within a period of 7
            days following the delivery date, and 15 days for Official Brand
            items only the period of returns.
          </p>
          <p>
          We do not accept returns for any reason, a
            once the return period stipulated above has elapsed. This
            does not affect your statutory rights against the seller.
          </p>
          <p>
          You can request the return of an item purchased from eShop,
            during the prescribed period, for the following reasons:
          </p>

          <br />
          <div class="table-responsive fs-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: "40%" }}>
                    <b>return reason</b>
                  </th>
                  <th className="text-center">
                    <b>Applicable category</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td valign="middle">I changed my mind</td>
                  <td>
                    <div>
                    All product categories except:
                      <ul>
                        <li>
                        Categories of products that cannot be returned
                          for reasons of hygiene or health as indicated
                          in section 3 below. This includes: lingerie;
                          underwear; pajamas; jerseys; underpants; items
                          of marriage; facial care; perfumes; hair care;
                          hand, foot and nail care; earrings;
                          health and relaxation items
                        </li>
                        <li>Tickets and events; and</li>
                        <li>Software.</li>
                      </ul>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td valign="middle">
                  The size is good but does not fit me
                  </td>
                  <td>Fashion items (including shoes) only</td>
                </tr>

                <tr>
                  <td valign="middle">
                  Item stopped working after use
                  </td>
                  <td>
                    <div>
                    All product categories except:
                      <ul>
                        <li>Clothes;</li>
                        <li>Sport &amp; fitness; and</li>
                        <li>Consumer products</li>
                      </ul>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td valign="middle">
                  Item is received broken or defective
                  </td>
                  <td>All product categories</td>
                </tr>

                <tr>
                  <td valign="middle">L'emballage est endommagé</td>
                  <td>Toutes les catégories de produits</td>
                </tr>

                <tr>
                  <td valign="middle">
                  The item is received with missing accessories
                  </td>
                  <td>All product categories</td>
                </tr>

                <tr>
                  <td valign="middle">L'article est utilisé ou périmé</td>
                  <td>
                    Toutes les catégories de produits à l'exception des
                    logiciels
                  </td>
                </tr>

                <tr>
                  <td valign="middle">
                  Item appears to be fake/counterfeit
                  </td>
                  <td>All product categories</td>
                </tr>

                <tr>
                  <td>Wrong item/color/size/model</td>
                  <td>All product categories</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <p>
          To be returned, items must be unused, not
            worn, and in the same condition in which you received them,
            with their original packaging and intact seal if applicable.
            However, the item can be returned if found to be faulty
            after delivery, or following reasonable use (according to the
            manufacturer's instructions for use) during the period of
            returns.
          </p>
        </div>

        <div className="page mt-5" id="page_4">
          <p id="q3" style={{ color: "#F49934" }}>
            <span className="h2 me-4">3. </span>
            <span className="h3">
            Items that cannot be returned            </span>
          </p>

          <p>
            <b>
            We do not accept returns of certain categories of
              products for health and hygiene reasons
            </b>
            <br />
            The safety of our customers is our priority, so some
            categories may not be returned for health reasons
            or hygiene, or if they are in danger of deteriorating or expiring
            rapidly. You cannot benefit from a return and refund
            for these items only if you have received the wrong product, or if
            you have received a defective, damaged or counterfeit item. You
            cannot benefit from a reimbursement for these categories of
            products with a reason for change of mind.
          </p>

          <p>
            <b>We do not accept returns of personalized items.</b>
            <br />
            We do not accept returns of used or worn products, except
            if the reason for return is related to the malfunction or
            damage to the product due to reasonable use (according to the
            manufacturer's instructions for use) during the period of
            returns.
            <br />
            The seller is not required to accept returns, except in the case
            where the items have been damaged or found to be faulty at
            delivery, or if they are different from the items originally
            orders.
          </p>

          <p>
            <b>
            We do not accept returns of items damaged after the
              delivery
            </b>
            <br />
            We do not accept returns of used or worn products, except
            if the reason for return is related to the malfunction or
            damage to the product due to reasonable use (according to the
            manufacturer's instructions for use) during the period of
            returns.
            <br />
            Items found to be faulty after delivery cannot
            be returned if they belong to the following categories: fashion,
            sports &amp; fitness and consumer products.
            <br />
            If an item is covered by a warranty, and suffers a
            damage or malfunction during the warranty period,
            they will not be covered by this Returns policy and
            Refunds, but by seller's warranty. Information
            relating to the warranty are displayed on each product sheet.
          </p>
        </div>

        <div className="page mt-5" id="page_5">
          <p id="q4" style={{ color: "#F49934" }}>
            <span className="h2 me-4">4. </span>
            <span className="h3">Returns Packaging</span>
          </p>

          <p>
            When returning an item for any reason, you must
            return it in the exact condition you received it from eShop, with
            its original packaging and all attached tags (for
            example, shoes must be returned to their box
            original shoes; the seals of the articles, including the
            audio or video recordings or software, must not be
            removed).
          </p>
          <p>
            Returned items are your responsibility until
            let them reach us. So make sure they are
            properly packaged and cannot be damaged in transit.
            road.
          </p>
          <p>
            You must not include in the packaging of the returned item
            any item that is not part of the returned item as it was
            was originally received from eShop. You must completely remove and
            eliminate all personal data concerning you and/or
            concerning a third party that could be present in an article,
            and unlock any account that may be linked to the item.
          </p>
          <p>
            eShop shall not be responsible for items mistakenly contained in
            a returned package, or any data breach resulting from a
            failure to eliminate personal data contained in a
            returned item
          </p>
        </div>

        <div className="page mt-5" id="page_6">
          <p id="q5" style={{ color: "#F49934" }}>
            <span className="h2 me-4">5. </span>
            <span className="h3">Refunds</span>
          </p>

          <p>
            If we accept your return, or if you prepay but do not receive
            not an item, we aim to refund the price to you
            of purchase of the article within the period indicated on the page of the periods of
            return.
          </p>
          <p>
            For nonconforming, defective or damaged items, you
            will also be reimbursed for all delivery costs
            returned items, once your return has been processed.
          </p>
          <p>
            If the return is not the result of eShop or seller error (for
            example, if you have changed your mind or if the size does not match
            not meet your expectations), you will not receive any refund of fees
            Delivery.
          </p>
          <p>
            The reimbursement request form must indicate the
            method(s) of payment for receiving refunds that you
            have.
          </p>
        </div>

        <div className="page mt-5" id="page_7">
          <p id="q6" style={{ color: "#F49934" }}>
            <span className="h2me-4">6. </span>
            <span className="h3">
              Rejected Return and Refund Requests and Confiscation
            </span>
          </p>

          <p>
            All items are inspected upon return for
            check the reasons for return provided.
          </p>
          <p>
            If your return request is not approved by eShop, you will not
            receive no refund of the purchase price or the cost of
            delivery, and we will make 2 re-delivery attempts within 7
            working days.
          </p>
          <p>
            If both redelivery attempts are unsuccessful, we will
            immediately notify that we will hold the item for 30
            additional days from the date of initial notification.
            Our notification will include details of the pickup location
            and opening hours.
          </p>
          <p>
            If you do not collect the item within the required time, you
            will lose the item, i.e. the item will become the property
            of eShop and that eShop may dispose of it in the manner it
            as deemed appropriate, for example by sale, charitable donation, recycling
            or destruction.
          </p>
        </div>

        <div className="page mt-5" id="page_8">
          <p id="q7" style={{ color: "#F49934" }}>
            <span className="h2 me-4">7. </span>
            <span className="h3">No exchanges</span>
          </p>

          <p>
          Please note that we do not offer exchanges. If you wish
            receive an item of a different color or size, we will
            please return the item in question and make a new
            ordered
          </p>
        </div>
        <Scrolltop />
      </section>
    </Helmet>
  );
};

export default Terms;
